import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import TextBanner from '../components/TextBanner';
import ModularBlocks from '../components/ModularBlocks';
import ProductListing from '../components/ProductListing';
import { getShopifyLocaleFields, getProductCards, buildUrl } from '../utils';

const ProductCategoryTemplate = ({
  data: {
    datoCmsProductCategory,
    shopifyCollection,
    allDatoCmsProduct: { datoCmsProducts },
    datoCmsAllProduct,
    datoCmsSite: {
      globalSeo: { titleSuffix },
    },
  },
}) => {
  const {
    locale,
    slugLocales,
    modularBlocksBefore,
    modularBlocksAfter,
    showFilters,
  } = datoCmsProductCategory;

  const { seo, title, handle, descriptionHtml, shopifyProducts } =
    getShopifyLocaleFields(shopifyCollection, locale);

  const shopifyProductsWithPositions = shopifyProducts.map(
    (shopifyProduct, i) => ({
      ...shopifyProduct,
      position: i,
    })
  );

  const products = getProductCards(
    datoCmsProducts,
    shopifyProductsWithPositions,
    locale
  );

  return (
    <Layout
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsProductCategory}
    >
      <HelmetDatoCms>
        <title>{`${seo.title || title}${titleSuffix}`}</title>
        {seo.description && (
          <meta name="description" content={seo.description} />
        )}
      </HelmetDatoCms>
      <main>
        <TextBanner
          title={title}
          content={descriptionHtml}
          breadcrumbs={[
            {
              text: datoCmsAllProduct.title,
              urlPath: buildUrl(datoCmsAllProduct.slug, datoCmsAllProduct),
            },
          ]}
        />
        <ModularBlocks items={modularBlocksBefore} locale={locale} />
        <ProductListing
          items={products.sort((a, b) => a.position - b.position)}
          category={handle}
          showFilters={showFilters}
          isAltCategoryPage={modularBlocksBefore}
        />
        <ModularBlocks items={modularBlocksAfter} locale={locale} />
      </main>
    </Layout>
  );
};

export const ProductCategoryTemplateQuery = graphql`
  query ProductCategoryTemplateQuery(
    $id: String!
    $locale: String!
    $shopifyCollection: String!
    $products: [String!]
  ) {
    datoCmsProductCategory(id: { eq: $id }) {
      locale
      slugLocales: _allSlugLocales {
        locale
        value
      }
      modularBlocksBefore {
        ...BackgroundImageCtaModularBlockFragment
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...FeaturedCustomerPhotosModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
        ...ImageGalleryModularBlockFragment
        ...TestimonialsModularBlockFragment
      }
      modularBlocksAfter {
        ...BackgroundImageCtaModularBlockFragment
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...FeaturedCustomerPhotosModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
        ...ImageGalleryModularBlockFragment
        ...TestimonialsModularBlockFragment
      }
      showFilters
      ...LinkFragment
    }
    shopifyCollection(handle: { eq: $shopifyCollection }) {
      seo {
        title
        description
      }
      title
      handle
      descriptionHtml
      shopifyProducts: products {
        ...ShopifyProductCardFragment
        options {
          name
          values
        }
      }
      translations {
        fr {
          seo {
            title
            description
          }
          title
          descriptionHtml
        }
      }
    }
    allDatoCmsProduct(
      filter: { locale: { eq: $locale }, shopifyProduct: { in: $products } }
    ) {
      datoCmsProducts: nodes {
        ...DatoCmsProductCardFragment
      }
    }
    datoCmsAllProduct(locale: { eq: $locale }) {
      title
      ...LinkFragment
    }
    datoCmsSite {
      globalSeo {
        titleSuffix
      }
    }
  }
`;

export default ProductCategoryTemplate;
